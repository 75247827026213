import "./Services.css";

import services1 from "../../Media/services/services1.jpg";

export default function Services() {
    return (
        <div className="services">
            <div className="servicesContainer">
                <img className="servicesImg" src={services1}/>

                <div className="offerContainer">
                    <h2>Mein Angebot</h2>

                    <p><span className="bold">Pflegeberatung</span> - ist eine ganzheitliche Beratung: Unterstützung und Information in allen Fragen rund um das Thema Pflege:</p>

                    <ul>
                        <li>
                            <span className="bold">Beratung zu Leistungen und Leistungsansprüchen</span> 
                            <br/> 
                            z.B. Pflegegeld, mobile Hauskrankenpflege, Essensdienste, Kurzzeitpflege, ...
                        </li>
                        <li>
                            <span className="bold">Information über Hilfsmittel und Kosten</span>
                            <br />
                            Pflegebett, Notrufuhr, ...
                        </li>
                        <li>
                            <span className="bold">Entlastungsgespräch für pflegende Angehörige</span>
                        </li>
                        <li>
                            <span className="bold">Schulung und Durchführung von pflegerischen Tätigkeiten für Angehörige und Betreuer (z.B. Lagerung, Verband, ...)</span>
                        </li>
                        <li>
                            <span className="bold">Beratung im Umgang mit Menschen mit Demenz</span>
                            <br />
                            Jeder Mensch hat das Recht, in Würde und in größtmöglicher Autonomie alt zu werden. Demenz ist eine schwerwiegende Erkrankung mit fortschreitendem Verlust aller kognitiven Fähigkeiten. Betroffene ziehen sich in ihre eigene Welt zurück; sie wirken unerreichbar.
                        </li>
                        <li>
                            <span className="bold">Demenztraining</span>
                            <br />
                            Regelmäßiges Training (Körperübungen, Gedächtnisübungen, Biographiegespräche, ...) hat Einfluss, dass sich die Demenzerkrankung verzögert. Die betroffene Person wirkt zufriedener.
                        </li>
                        <li>
                            <span className="bold">Vorsorgeplanung</span> - für selbstbestimmtes Leben in der letzten Lebensphase
                        </li>
                        <li>
                            <span className="bold">Information über rechtliche Möglichkeiten wie Vorsorgevollmacht und Patientenverfügung</span>
                        </li>
                        <li>
                            <span className="bold">Aromaberatung</span> - Ätherische Öle wirken mit der geballten Kraft der Natur. Sie stärken die Abwehrkräfte, gleichen Stimmungsschwankungen aus und sind für viele  Bereiche einsetzbar.
                        </li>
                        <li>
                            <span className="bold">Palliativpflege</span> - Pflege und Begleitung sterbenskranker Menschen
                            <br />
                            Es können seitens der Angehörigen Ängste bestehen, dass es die Familie  überfordert, Sterben zuhause zu ermöglichen. <br />
                            Mit offenen Gesprächen und der Vermittlung von Wissen über die zu erwartenden Situationen können diese Bedenken zerstreut werden und dem Wunsch des kranken Menschen „zu Hause zu bleiben bis zum Schluss“ entsprochen werden.
                        </li>
                        <li>
                            <span className="bold">Vorträge zu verschiedenen Themen</span>
                            <br />
                            Demenz, sorgende Gemeinde - Caring Community, Gesundheitskompetenz, Letzte&nbsp;Hilfe&nbsp;Kurs,&nbsp;...
                        </li>
                        <li>
                            <span className="bold">Angebot für Gemeinden</span> zur Zusammenarbeit als COMMUNITY NURSE
                        </li>
                    </ul>
                </div>

                <div className="priceContainer">
                    <h2>Preise</h2>
                    <table>
                        <tbody>
                            <tr>
                                <td>Pflegeberatungs-Gespräch</td>
                                <td>€ 75,- pro Stunde</td>
                            </tr>
                            <tr>
                                <td>Für jede weitere ½ Stunde</td>
                                <td>€ 30,-</td>
                            </tr>
                            <tr><td><br /></td></tr>
                            <tr>
                                <td>Demenztraining, regelmäßig 1x/Woche</td>
                                <td>€ 60,- pro Stunde</td>
                            </tr>
                            <tr><td><br /></td></tr>
                            <tr>
                                <td>Pflegerische Betreuung zur kurzfristigen Überbrückung</td>
                                <td>€ 45,- pro Stunde</td>
                            </tr>
                        </tbody>
                    </table>

                    <br />
                    <p>
                        Fahrtkosten sind im Umkreis von 20 km (2133 Hagenberg) inkludiert, <br/> für die Kilometer darüber hinaus berechne ich € 1,20/km (ab 20. km bis Ziel).
                    </p>
                </div>

                {/* <li>
                    Pflegeberatungs-Gespräch - €75,- pro Stunde

                    </li>
                
                    Fahrtkosten sind im Umkreis von 20 km (2133 Hagenberg) inkludiert, für die Kilometer darüber hinaus berechne ich € 1,20/ km (ab 20. Km bis Ziel). */}


                <div className="appointmentContainer">
                    <h2>Terminabsage</h2>
                    <p>
                        Bereits vereinbarte Termine zur Gesundheitsberatung können Sie bis 24 Stunden vorher kostenlos absagen. Wird später oder gar nicht abgesagt, sind die Kosten für die versäumte Einheit zu entrichten.
                    </p>
                </div>
            </div>
        </div>
    );
}