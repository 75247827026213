import "./Home.css";

export default function Home() {
    return (
        <div className="home">
            <div className="homeBanner">
                <div className="banner" ></div>
            </div>

            <div className="homeContainer">
                <div className="homeExplanation">
                    <h2></h2>

                    <p>
                        <span>Pflegeberatung...</span><br/>
                        ...gibt Hilfestellung bei der
                        Bewältigung von Pflegebedürftigkeit
                        und leistet einen
                        Beitrag dazu, den Verlust von
                        Ressourcen zu verhindern
                        oder auszugleichen.
                    </p>
                </div>

                <div className="homeNote1">
                    <div className="row">
                        <div className="column">
                            <p>
                                Wenn Unterstützung und Pflege
                                benötigt wird, sind viele Fragen da.
                            </p>
                        </div>
                        <div className="column" />
                    </div>

                    <div className="row">
                        <div className="column" />
                        <div className="column">
                            <p>
                                Und man stößt an seine Grenzen.
                                Da tut es gut, sich kompetente Hilfe zu holen.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="homeQuote">
                    <h2>Leben ist Veränderung</h2>

                    <div>
                        <p>„Und jedem Anfang wohnt ein Zauber inne, der uns beschützt und der uns hilft, zu leben.“</p>

                        <p >Hermann Hesse/Stufen</p>
                    </div>
                </div>

                <div className="homeNote2">
                    <p>Ich möchte Sie in dem Prozess der Veränderungen begleiten und Ihnen die Gewissheit geben: </p>
                    
                    <p className="bold">„Ich werde nicht allein gelassen!“</p>
                </div>
            </div>
        </div>
    );
}