import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Home from "./Content/Home/Home";

import About from "./Content/About/About";
import Explanation from "./Content/Explanation/Explanation";
import Services from "./Content/Services/Services";
import Contact from "./Content/Contact/Contact";
import TermsOfUse from "./Content/TermsOfUse/TermsOfUse";

export default function Router() {
	return (
		<Routes>
			<Route exact path={"/"} element={<Home />} />

			<Route exact path="/about" element={<About />} />
			<Route exact path="/explanation" element={<Explanation />} />
			<Route exact path="/services" element={<Services />} />
			<Route exact path="/contact" element={<Contact />} />
			<Route exact path="/termsofuse" element={<TermsOfUse />} />

			<Route path="*" element={<Navigate to="/" replace />} />
		</Routes>
	);
};
