import "./App.css";

import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "./Components/Header/Header";
import Router from "./Router";
import Footer from "./Components/Footer/Footer";

const TITLE = "MKS Pflegeberatung";

function App() {
	useEffect(() => {
		document.title = TITLE;
	}, []);

	const headerRef = useRef();
	const location = useLocation();

	useEffect(() => {
		headerRef.current.closeHamburger();
	}, [location]);

	return (
		<div className="App">
			<Header ref={headerRef}/>

			<Router />

			<Footer />

			{/* <ScrollToTop /> */}
		</div>
	);
}

export default App;
