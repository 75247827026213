import "./Footer.css";

export default function Footer() {
    return (
        <div className="footer">
            <div className="contactDataContainer">
                <h4>MKS Pflegeberatung</h4>
                <p className="contactData">
                    Monika Karl-Schild
                    <br />
                    2133 Hagenberg 104
                    <br />
                    <a className="contactDataLink" href="mailto:info@mks-pflegeberatung.at">
                        info@mks-pflegeberatung.at
                    </a>
                    <br />
                    <a className="contactDataLink" href="tel:+43 670 6581666">
                        +43 670 6581666
                    </a>
                    <br />
                    19-GBR-119546
                </p>
            </div>
        </div>
    );
}