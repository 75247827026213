import "./Explanation.css";

export default function Explanation() {
    return (
        <div className="explanation">
            <div className="explanationBanner">
                <div className="banner" ></div>
            </div>

            <div className="explanationContainer">

                <p className="main">
                    Die Pflegeberatung ist ein neuer Tätigkeitsbereich in der Pflege, dessen Zielsetzung es ist, Patienten und/oder deren Angehörige umfassend über die Möglichkeiten der Pflege zu informieren.
                    Dabei erhalten Sie bei einem Hausbesuch als pflegebedürftige Person oder pflegender Angehöriger sämtliches Wissen über Unterstützungsmöglichkeiten wie Entlastungs-, Hilfsangebote sowie Sozialleistungen, die Ihnen zustehen.
                    Dabei wird in der Pflegeberatung Ihre individuelle Situation eingeschätzt, die entsprechenden Leistungen werden Ihnen vorgestellt  und ein individueller Versorgungsplan wird für Sie geschrieben.
                </p>

                <div>
                    <h4>Definition der freiberuflichen Pflegeberatung laut dem GUKG (Gesundheits- und Krankenpflegegesetz)</h4>

                    <p>
                        Freiberufliche Pflegeberatung durch Diplomiertes Gesundheits- und Krankenpflegepersonal darf alle Tätigkeiten des § 14 Gesundheits- und Krankenpflegegesetzes durchführen.
                    </p>
                    <p>
                        Dies umfasst die pflegerischen Kernkompetenzen des gehobenen Dienstes für Gesundheits- und Krankenpflege, die eigenverantwortliche Erhebung des Pflegebedarfes sowie Beurteilung der Pflegeabhängigkeit, die Diagnostik, Planung, Organisation, Durchführung, Kontrolle und Evaluation aller pflegerischen Maßnahmen (Pflegeprozess) in allen Versorgungsformen und Versorgungsstufen, die Prävention, Gesundheitsförderung und Gesundheitsberatung im Rahmen der Pflege sowie der Pflegeforschung.
                    </p>
                    <p>
                        (Quelle:&nbsp;
                        <a href={"https://www.jusline.at/gesetz/gukg/paragraf/14"} target="_blank" rel="noopener noreferrer">
                            https://www.jusline.at/gesetz/gukg/paragraf/14
                        </a>
                        , Stand per 31.12.2022)
                    </p>
                </div>

                <div>
                    <h4>Gesundheitsberuferegister</h4>

                    <p>
                        Das Gesundheitsberuferegister ist ein elektronisches Verzeichnis, in das Angehörige der Gesundheits- und Krankenpflegeberufe sowie der gehobene medizinisch - technische Dienst eingetragen sind. Die Registrierung ist Voraussetzung für die Ausübung des jeweiligen Gesundheitsberufes. Das Verzeichnis enthält berufsspezifische Daten dieser Berufsangehörigen. Ein Teil dieser Daten ist auch für Sie öffentlich einsehbar. Die eingetragenen Personen erhalten einen Berufsausweis.
                    </p>
                    <p>
                        (Quelle:&nbsp;
                        <a href={"https://www.gesundheit.gv.at/professional/gbr/oeffentliches-register"} target="_blank" rel="noopener noreferrer">
                            https://www.gesundheit.gv.at/professional/gbr/oeffentliches-register
                        </a>
                        )
                    </p>
                </div>
            </div>
        </div>
    );
}