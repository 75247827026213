import "./Contact.css";

import emailjs, { init } from "@emailjs/browser";

export default function Contact() {
    init("OdetM6Q97h50mDhHv");

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let name = e.target.name.value;
        let email = e.target.email.value;
        let phoneNumber = e.target.phoneNumber.value;
        let subject = e.target.subject.value;
        let message = e.target.message.value;

        let data = {
            "name": name,
            "email": email,
            "phoneNumber": phoneNumber,
            "subject": subject,
            "message": message,
        };

        if (name && email && phoneNumber && subject && message) {
            if (isValidEmail(email)) {
                emailjs.send("service_pirn1ho", "template_p7zqzts", data)
                    .then(function (response) {
                        alert("Ihre Nachricht wurde erfolgreich gesendet!");
                        e.target.reset();
                    }, function (error) {
                        alert("Es ist ein Fehler aufgetreten. Bitte versuchen Sie später nochmal.");
                    });
            } else {
                alert("Bitte eine gültige E-Mail Adresse eingeben!");
            }
        } else {
            alert("Bitte alle Felder ausfüllen!");
        }
    }

    return (
        <div className="contact">
            <p className="contactDescription">
                Für eine direkte Benachrichtigung füllen Sie bitte folgendes Formular aus. Ich bemühe mich um eine schnelle Bearbeitung Ihrer Nachricht.
            </p>

            <div className="inputContainer">
                <form className="contactForm" onSubmit={handleSubmit}>
                    <div className="contactInputContainer">
                        <label htmlFor="name" className="contactLabel">Name</label>
                        <input type="string" name="name" id="name" className="contactInput"></input>
                    </div>

                    <div className="contactInputContainer">
                        <label htmlFor="email" className="contactLabel">Email</label>
                        <input type="email" name="email" id="email" className="contactInput"></input>
                    </div>

                    <div className="contactInputContainer">
                        <label htmlFor="phoneNumber" className="contactLabel">Telefonnummer</label>
                        <input type="tel" name="phoneNumber" id="phoneNumber" className="contactInput"></input>
                    </div>

                    <div className="contactInputContainer">
                        <label htmlFor="subject" className="contactLabel">Betreff</label>
                        <input type="string" name="subject" id="subject" className="contactInput"></input>
                    </div>

                    <div className="contactInputContainer">
                        <label htmlFor="message" className="contactLabel">Nachricht</label>
                        <textarea type="text" rows="8" name="message" id="message" className="contactInput contactTextarea"></textarea>
                    </div>


                    <button type="submit" className="buttonSendMessage">Abschicken</button>
                </form>
            </div>
        </div>

    );
}